import * as React from "react";
import { Link } from "gatsby";

import logo from "../img/friends_chances_park_logo.png";
import facebook from "../img/social/facebook.svg";
import instagram from "../img/social/instagram.svg";
import twitter from "../img/social/twitter.svg";
import vimeo from "../img/social/vimeo.svg";
import greenFlag from "../img/green-flag-award-logo.jpg"
import heritageSite from "../img/green-heritage-site-logo.jpg"

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-background-black has-text-white-ter">
        <div className="content has-text-centered">
          <img
            src={logo}
            alt="Friends of Chances Park"
            style={{ width: "15.7em", height: "4.7em" }}
          />
        </div>
        <div className="content has-text-centered has-background-black has-text-white-ter">
          <div className="container has-background-black has-text-white-ter">
            <div style={{ maxWidth: "100vw" }} className="columns">
              <div className="column is-4">
                <section className="menu">
                  <ul className="menu-list">
                    <li>
                      <Link to="/" className="navbar-item">
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/about">
                        About
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/history">
                        History
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/blog">
                        What's on?
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/supporters">
                        Supporters
                      </Link>
                    </li>
                    <li>
                      <a
                        className="navbar-item"
                        href="/admin/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Admin
                      </a>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/contact">
                        Contact
                      </Link>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column is-4">
                <section>
                  <ul className="awards">
                    <li>
                      <img
                        src={greenFlag}
                        alt="Green Flag Award"
                      />
                    </li>
                    <li>
                      <img
                        src={heritageSite}
                        alt="Green Heritage Site Award"
                      />
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column is-4 social">
                <a title="facebook" href="https://www.facebook.com/FriendsofChancesPark">
                  <img
                    src={facebook}
                    alt="Facebook"
                    style={{ width: "1em", height: "1em" }}
                  />
                </a>
                <a title="twitter" href="https://twitter.com/ChancesPark">
                  <img
                    className="fas fa-lg"
                    src={twitter}
                    alt="Twitter"
                    style={{ width: "1em", height: "1em" }}
                  />
                </a>
              </div>
            </div>
            <div>
              <p>
                © Copyright Friends of Chances Park 2013-2022. All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </footer>
    );
  }
};

export default Footer;
